<template>
	<div class="container" v-if="allData">
		<h3>INTRO</h3>

		<div class="item">
			<img src="@/assets/icons/location.svg">
			<h4>{{ user.location }}</h4>
		</div>
	</div>
</template>


<script>
	export default {
		name: "Intro",
		props: {
			user: Object
		},
		data() {
			return {
				followerCount: (Math.floor(Math.random()*Math.floor(1000))+1) + " followers"
			};
		},
		computed: {
			allData() {
				const isEmpty = (x) => (x == undefined || x === null || x === "");
				return !isEmpty(this.user.location);
			}
		}
	}
</script>


<style lang="scss" scoped>
	.container {
		@include side-component;
		padding: 10px;

		@include respond-to("small") {
			display: none
		}

		h3 {
			@include side-component-title;
			text-align: center;
			margin-bottom: 10px;
		}

		.item {
			display: flex;
			margin-left: 10px;

			img {
				@include white-filter;
				margin-right: 10px;
			}
			h4 {
				@include dot-overflow;
				color: var(--text-light);
				margin: 15px;
			}
		}
	}
</style>