<template>
  <div class="container">
      <h3>PC SPECS</h3>
      
      <div class="item">
          <p v-html="convert"></p>
      </div>
  </div>
</template>

<script>
export default {
    name: "PcSpecs",
    props: {
        pc_specs: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
        }
    },
    computed: {
        convert() {
            return this.pc_specs !== null ? this.pc_specs.replace(/\n\r?/g, "<br>") : "";
        }
    }
}
</script>

<style lang="scss" scoped>
    .container {
        @include side-component;
        // @include respond-to('small') {
        //     margin: 0 auto;
        // }

        padding: 10px;


        h3 {
			@include side-component-title;
			text-align: center;
			margin-bottom: 10px;
		}

        .item {
            display: flex;
			margin-left: 10px;
            height: auto;
            max-width: 400px;

            @include respond-to('small') {
				max-width: 100%;
			}

            p {
                word-break: break-word;
            }
        }

        
    }
</style>