<template>
  <div class="container">
    <Navbar class="navbar" ref="nav" />

    <div class="grid user-page" ref="grid">
      <UserHeader
        class="header"
        v-if="isReady"
        :user="user"
        :info="info"
        :key="key"
      />

      <div class="leftSide" v-if="isReady && info">
        <PcSpecs :pc_specs="user.pc_specs" />
        <Intro :user="user" :key="key + 'c'" />
        <UserGroups :id="user.id" :key="key + 'a'" :form="isOwnProfile" />
      </div>

      <div class="posts">
        <div class="loader" v-if="loadingPosts">
          <div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else>
          <div class="bio">
            <h3>BIO</h3>
  
            <p>{{ user.bio }}</p>
  
            <div class="buttons" v-if="!isOwnProfile">
              <div class="bio__follow">
                <span style="margin: 10px;">Follow This User</span>
                <FollowButton :id="user.id" />
              </div>
            </div>
          </div>
          
          <Post
            v-if="posts.length > 0"
            v-for="post in posts"
            :key="post.id"
            v-bind="post"
          />

          <div class="loader" v-if="loadingMore">
            <div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <h2 v-if="posts.length === 0" class="noPosts">
            User hasn't posted anything yet.
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Navbar from "@/components/Navbar";
import UserHeader from "@/components/UserHeader";
import UserGroups from "@/components/side/UserGroups";
import Intro from "@/components/side/Intro";
import Post from "@/components/post/Post";
import PcSpecs from "@/components/side/PcSpecs";
import FollowButton from "@/components/FollowButton";

export default {
  name: "User",
  components: {
    Navbar,
    UserHeader,
    FollowButton,
    UserGroups,
    Intro,
    Post,
    PcSpecs,
  },
  data() {
    return {
      user: null,
      info: null,
      isReady: false,
      key: 1,
      posts: [],
      loadingPosts: true,
      loadingMore: false,
      postsCount: 0,
      limit: 5,
      trackOffset: 0,
      timeout: undefined
    };
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    document.querySelector("body").style.overflow = "auto";
    this.getData();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    throttle(func, limit) {
      if (!this.timeout) {
        func();
        this.timeout = setTimeout(() => {
          this.timeout = undefined;
        }, limit);
      }
    },
    async onScroll() {
      const windowHeight = window.innerHeight;
      const scrollOffset = window.pageYOffset + windowHeight;
      const pageHeight = this.$refs.grid.offsetHeight + this.$refs.nav.$el.offsetHeight;
      if (scrollOffset + 200 >= pageHeight) {
        if(this.posts.length === this.postsCount) {
          return;
        }

        this.throttle(this.loadMore, 500);
      }
		},
    async getData() {
      const hash = this.$route.params.userIdentity.split("-")[this.$route.params.userIdentity.split("-").length - 1];
      const response = await this.$api.getUserInfo(hash, this.userStore.accessToken);
      if(!response.success) {
        const { status, message } = response.error;
        if(status === 404) {
          this.user = { username: message };
          this.info = null;
          this.isReady = true;
        } 

        return;
      }

      const { user, info: userInfo } = response;
      this.user = user;
      this.info = userInfo;
      this.isReady = true;
      // If user and info were succesfull start loading posts
      this.getPosts();
    },
    async loadMore() {
        this.trackOffset += 5;
        this.loadingMore = true;
        const response = await this.$api.getUserPosts(this.user.id, this.limit, this.trackOffset, this.userStore.accessToken)
        const { success, posts } = response;
        if(!success) {
          this.loadingMore = false;
          return;
        }
        this.posts.push(...posts);
        this.loadingMore = false;

    },
    async getPosts(limit = 5, offset = 0) {
      this.loadingPosts = true;
      this.trackOffset = 0;
      this.postsCount = 0;

      const response = await this.$api.getUserPosts(this.user.id, limit, offset, this.userStore.accessToken)
      const { success, posts, postsCount } = response;
      if(!success) {
        const { status, message } = response.error;
        this.loadingPosts = false;
        return;
      }

      this.posts = posts;
      this.postsCount = postsCount;
      this.loadingPosts = false;
    },
  },
  computed: {
    ...mapStores(useUserStore),
    isOwnProfile() {
      return parseInt(this.user.id) === parseInt(this.userStore.user.id);
    },
  },
  watch: {
    "$route.params.userIdentity": function() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  top: 0;
  width: 100%;
  z-index: 100;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto auto auto;
  gap: 20px 15px;
  grid-template-areas:
    "header header"
    "leftSide posts"
    "leftSide posts";

  @include respond-to("medium") {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "header"
      "leftSide"
      "bio"
      "posts";
  }

  .header {
    grid-area: header;
  }

  .bio {
    grid-area: bio;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    width: 100%;
    max-width: 600px;

    word-break: break-word;
    font-size: 1em;
    &__follow {
      display: flex;
      align-items: center;
    }

    h3 {
      text-align: center;
      margin-bottom: 0;
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px;

      button {
        @include button($gradient: true);
      }
    }
  }

  .posts {
    grid-area: posts;
    overflow: hidden;

    max-width: 600px;
    padding-left: 120px ;

    @include respond-to("medium") {
      max-width: 90%;
      padding: 0 10px;
      margin: 0 auto;
    }

    .noPosts {
      text-align: center;
      color: var(--text);
      padding-top: 20%;
    }
  }
  .leftSide {
    margin-left: 20px;
    float: left;

    @include respond-to("medium") {
      margin-right: 20px;
    }

    > * {
      margin-bottom: 20px;
    }
  }

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;

    div {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;

      div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid white;
        border-radius: 50%;
        animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: white transparent transparent transparent;

        &:nth-child(1) {
        animation-delay: -0.45s;
        }

        &:nth-child(2) {
        animation-delay: -0.3s;
        }

        &:nth-child(3) {
        animation-delay: -0.15s;
        }
      }
    }
  }
}
</style>
