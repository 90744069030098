<template>
	<header>
		<div class="cover" :style="{ background: coverImg }">
			<div class="blur"></div>
			<div class="info">
				<img class="profileImage" :src="profileImg">

				<img v-if="isOwnProfile" class="editButton" src="@/assets/icons/edit-2.svg" @click="edit">

				<h1>{{ user ? user.username : '' }}</h1>
				<h2>{{ mainName }}</h2>

				<div v-if="tags !== false" class="tags" :title="allTags">
					<h3>Tags I follow</h3>
					<span>{{ tags }}</span>
				</div>
			</div>
		</div>
	</header>
</template>


<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

const isEmpty = x => (x == undefined || x == null || x === "");

export default {
	name: "UserHeader",
	props: {
		user: Object,
		info: Object
	},
	methods: {
		edit() {
			this.$router.push({ path: "/setup", query: {editing: true} });
		}
	},
	computed: {
		...mapStores(useUserStore),
		profileImg() {
			return this.$formatter.formatProfileImg(this.user ? this.user.img_url : null);
		},
		coverImg() {
			let img = this.$formatter.formatCoverImg(this.user ? this.user.cover_img_url : null);
			return (img === false) ? "var(--dark)" : "url('"+img+"')";
		},
		mainName() {
			if(this.user) {
				return (isEmpty(this.user.name)) ? '' : this.user.name;
			} else {
				return '';
			}
		},
		tags() {
			if(!this.info) return false;

			let n = 6;		// How many tags to show
			if (this.info.subscribed.length > 0)
				return this.info.subscribed.slice(0, n).map(x => x.name).join(", ") + ((this.info.subscribed.length > n) ? ", ..." : "");
			else
				return false;
		},
		allTags() {
			return this.info.subscribed.map(x => x.name).join(", ")
		},
		isOwnProfile() {
			return this.user ? (this.user.id === this.userStore.user.id) : false;
		}
	}
}
</script>


<style lang="scss" scoped>
	header {
		display: flex;

		width: 100%;
		height: 300px;

		@include respond-to('small') {
			height: 450px;
		}		

		color: var(--text);

		.cover {
			position: relative;
            background-size: cover !important;
            background-position: center !important;

			@include respond-to('small') {
				width: 100%;
			}

			.blur {
				@include blur($radius: 20px);
				border-radius: 0 0 20px 0;
			}
			@include cover-img();
			width: 75%;
			border-radius: 0 0 20px 0;

			.info {
				display: grid;
				grid-template-columns: auto auto auto;
				grid-template-rows: auto auto auto;
				gap: 0 20px;
				grid-template-areas:
					"image name edit"
					"image nickname edit"
					"image tags edit";

				position: relative;
				top: calc(50% - 90px);
				left: 5%;
				width: 70%;

				@include respond-to('small') {
					grid-template-columns: auto auto;
					grid-template-rows: auto auto auto auto;
					grid-template-areas: 
						"image edit"
						"name name"
						"nickname nickname"
						"tags tags";
					top: 20px;
				}

				h1, h2, h3, span {
					color: white;
					text-shadow: 1px 1px darken(white, 80%);
					//mix-blend-mode: difference;		// If there's a cover img, this will make the text stand out
					//filter: invert(1) grayscale(1) contrast(9);
				}

				.editButton {
					grid-area: edit;
					@extend .pointer;
					@extend .noselect;
					@extend .nodrag;

					@include gradient;
					border-radius: 10px;
					padding: 5px;


					&:hover {
						@include popup(1.1);
					}
				}
				.profileImage {
					grid-area: image;
					@include profile-img(180px);
					@include box-shadow($color: rgba(0, 0, 0, 0.2));
					border: 4px solid var(--dark);

					// @include respond-to('small') {
					// 	display: none;
					// }
				}
				h1 {
					grid-area: name;
					margin: 0;
					margin-top: 30px;

				@include respond-to('medium') {
					width: 30vw;
				}

					// This one is not working, not sure why
					@include respond-to('small') {
						width: 40vw;
					}
				}
				h2 {
					grid-area: nickname;
					margin: 0;
					font-weight: normal;
				}
				.tags {
					grid-area: tags;
					max-width: auto;
					max-height: 4em;

					h3 {
						margin: 0;
					}
				}
			}
		}
	}
</style>
